import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/community-portal/community-portal/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const InfoBlock = makeShortcode("InfoBlock");
const Box = makeShortcode("Box");
const Callout = makeShortcode("Callout");
const Link = makeShortcode("Link");
const List = makeShortcode("List");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Meetups`}</h1>
    <InfoBlock mdxType="InfoBlock">
      <p><img parentName="p" {...{
          "src": "/images/illustrations/Tall-3.png",
          "alt": "Meetups"
        }}></img></p>
      <Box mdxType="Box">
        <h2>{`Bringing together people to talk Maker, Dai or DeFi at a meetup is a fantastic way for you to help the community.`}</h2>
      </Box>
    </InfoBlock>
    <Callout icon="lightbulb" mdxType="Callout">
      <p>{`MakerDAO helps fund meetups centered around Maker and/or Dai. `}<Link to="/funding/meetup-funding/" mdxType="Link">{`Learn about meetup funding`}</Link></p>
    </Callout>
    <h2>{`Meetup Guides`}</h2>
    <p>{`We've put together these guides to help you create the best possible meetup based on the lessons we've learned hosting meetups ourselves.`}</p>
    <List mdxType="List">
      <Link to="/contribute/meetups/getting-started-guide/" mdxType="Link">
        <p><strong parentName="p">{`Getting Started`}</strong></p>
        <p>{`The basics for hosting a meetup. Start here if it’s your first time hosting`}</p>
      </Link>
      <Link to="/contribute/meetups/event-formats-guide/" mdxType="Link">
        <p><strong parentName="p">{`Event Format`}</strong></p>
        <p>{`How to choose the best format for your meetup based on your audience.`}</p>
      </Link>
      <Link to="/contribute/meetups/budget-guide/" mdxType="Link">
        <p><strong parentName="p">{`Budget`}</strong></p>
        <p>{`How to budget effectively and a reminder of meetup costs.`}</p>
      </Link>
      <Link to="/contribute/meetups/promotion-guide/" mdxType="Link">
        <p><strong parentName="p">{`Promotion`}</strong></p>
        <p>{`How and where to promote your meetup.`}</p>
      </Link>
    </List>
    <Callout icon="pencil" mdxType="Callout">
      <p>{`If you have insight you’d like to share, feel free to suggest new guides or edits to the existing ones. You can find out how on our `}<a parentName="p" {...{
          "href": "/contribute/content/"
        }}>{`content page`}</a></p>
    </Callout>
    <h2>{`Resources and Inspiration`}</h2>
    <p>{`Looking for a Dai logo for your promotional material or just want to see how others have communicated the power of Maker? These should have you covered.`}</p>
    <List mdxType="List">
      <Link to="https://www.notion.so/makerdao/Maker-Brand-ac517c82ff9a43089d0db5bb2ee045a4" mdxType="Link">
        <p><strong parentName="p">{`Design Files and Assets`}</strong></p>
        <p>{`Official Maker and Dai logos, badges and product screenshots.`}</p>
      </Link>
      <Link to="/funding/meetup-funding/" mdxType="Link">
        <p><strong parentName="p">{`Meetup Funding`}</strong></p>
        <p>{`Want help funding your meetup? Apply to see if your meetup is eligible.`}</p>
      </Link>
      <Link to="https://www.notion.so/makerdao/Maker-Brand-ac517c82ff9a43089d0db5bb2ee045a4" mdxType="Link">
        <p><strong parentName="p">{`Presentation Slides`}</strong></p>
        <p>{`Slides about Maker and Dai already created for your convenience.`}</p>
      </Link>
      <Link to="https://github.com/makerdao/awesome-makerdao#videos" mdxType="Link">
        <p><strong parentName="p">{`Example Presentations`}</strong></p>
        <p>{`Links to recordings of some great meetup presentations.`}</p>
      </Link>
    </List>
    <Box sx={{
      padding: 4
    }} mdxType="Box">
      <Column mdxType="Column">
        <Box mdxType="Box">
          <h2>{`Disclaimer`}</h2>
          <p>{`These meetups are not for speculating on the price of the token or general hype. Events should focus on the MakerDao ecosystem, how MakerDao and Dai interact with DeFi, decentralized governance, and other relevant topics.`}</p>
          <p>{`The goal of hosting meetups is to bring people together and educate them about decentralized finance, Dai, and MakerDAO. When talking about the MKR token, the goal should be to educate the attendees about the purpose and utility of the token.`}</p>
        </Box>
      </Column>
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      